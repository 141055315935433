<template>
  <div class="pay-type">
    <NavBar title="收银台" left-arrow @click-left="goBack" v-if="isWeixin_status == false"></NavBar>
    <Cell title="支付金额" title-class="title-left"	value-class="value-class" value="￥100" style="margin-top:10px"></Cell>
    <Panel title="选择支付方式" style="margin-top:10px">
      <RadioGroup v-model="pay_type">
        <Cell @click="pay_type = '1'">
          <template slot="title">
           <div class="pay-item">
             <img src="@static/home/wx_pay.png" alt="">
             <div class="pay-item-lable">
               <span>微信支付</span>
               <label>推荐微信用户支付</label>
             </div>
           </div>
          </template>
          <Radio slot="right-icon" name="1" />
        </Cell>
       <Cell @click="pay_type = '2'">
          <template slot="title">
            <div class="pay-item">
              <img src="@static/home/alipay.png" alt="">
              <div class="pay-item-lable">
                <span>支付宝支付</span>
                <label>推荐支付宝用户支付</label>
              </div>
            </div>
          </template>
          <Radio slot="right-icon" name="2" />
        </Cell>
      </RadioGroup>
    </Panel>
    <div class="make_pay">
      <Button type="primary" color="linear-gradient(-90deg,rgba(255,102,0,1) 0%,rgba(255,153,3,1) 100%)" block>立即支付</Button>
    </div>
  </div>
</template>
<script>
import { Panel, Cell, NavBar, Radio, RadioGroup, Button } from "vant";
export default {
  components: {
    Panel, NavBar, Cell, Radio, RadioGroup, Button
  },
  data() {
    return {
      pay_type: '1'
    }
  }
}
</script>
<style lang="scss" scoped>
$bgcolor:rgba(244,244,244,1);
.pay-type {
  width: 100%;
  height: 100vh;
  background: rgba($color: $bgcolor, $alpha: 1.0);
  .title-left {
    font-size: 14px;
    color: #000000;
  }
  .value-class {
    color: rgba(255, 102, 0, 1);
  }
  .make_pay {
    width: 100%;
    position: fixed;
    bottom: 20px;
    left: 48%;
    padding: 0 20px;
    margin-left: -48%;
  }
  .pay-item {
    display: flex;
    align-items: center;
    img {
      width: 30px;
      height: 30px;
    }
  }
  .pay-item-lable {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    span:nth-child(1) {
      color: #000000;
      font-size: 14px;
    }
    label {
      color: #696969;
      font-size: 12px;
    }
  }
}
</style>